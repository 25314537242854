\<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="고장정보">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable &&!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-checkbox
              :editable="editable &&!disabled"
              :isFlag="true"
              label="고장여부"
              name="breakdownFlag"
              v-model="data.breakdownFlag"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <c-textarea
              :editable="editable &&!disabled && breakdowned"
              :required="breakdowned"
              :rows="1"
              label="고장내역"
              name="breakRemark"
              v-model="data.breakRemark">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="editable &&!disabled && breakdowned"
              :required="breakdowned"
              label="고장 시작일시"
              type="datetime"
              :minuteStep="30"
              @input="dateChanged"
              name="breakdownStartDtm"
              v-model="data.breakdownStartDtm">
            </c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="editable &&!disabled && breakdowned"
              :required="breakdowned"
              :start="data.breakdownStartDtm"
              label="고장 종료일시"
              type="datetime"
              :minuteStep="30"
              @input="dateChanged"
              name="breakdownEndDtm"
              v-model="data.breakdownEndDtm">
            </c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :editable="false"
              label="고장지속시간"
              name="breakdownTime"
              suffix="시간(h)"
              type="number"
              v-model="data.breakdownTime">
            </c-text>
          </div>
          <c-card class="cardClassDetailForm" title="고장 카탈로그">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-checkbox
                  :editable="editable &&!disabled && breakdowned"
                  codeGroupCd="WO_BD_PHENOMENON_CD"
                  valueText="codeName"
                  valueKey="code"
                  label="현상"
                  name="phenomenons"
                  :inline="false"
                  v-model="data.phenomenons"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-checkbox
                  :editable="editable &&!disabled && breakdowned"
                  codeGroupCd="WO_BD_CAUSE_CD"
                  valueText="codeName"
                  valueKey="code"
                  label="원인"
                  name="causes"
                  :inline="false"
                  v-model="data.causes"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-checkbox
                  :editable="editable &&!disabled && breakdowned"
                  codeGroupCd="WO_BD_ACTION_CD"
                  valueText="codeName"
                  valueKey="code"
                  label="조치"
                  name="actions"
                  :inline="false"
                  v-model="data.actions"
                />
              </div>
            </template>
          </c-card>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-top:25px !important;">
            <c-textarea
              :editable="editable &&!disabled && breakdowned"
              :rows="9"
              label="추가 조치 사항"
              name="remark"
              v-model="data.remark">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-top:50px !important;">
            <c-upload 
              :isPhoto="true"
              :editable="editable &&!disabled && breakdowned"
              :attachInfo="attachInfo"
              label="설비 고장사진">
            </c-upload>
          </div>
        </template>
      </c-card>
      
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  props: {
    param: {
      type: Object,
      default: () => ({
        workResultId: '',
        equipmentCd: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_ORDER_RESULT_FILE',
        taskKey: '',
      },
      editable: false,
      data: {
        workResultBreakdownId: '',  // 고장일련번호
        workResultId: '',  // 작업결과번호
        equipmentCd: '',  // 설비코드
        breakdownFlag: 'N',  // 고장여부
        breakdownStartDtm: '',  // 고장 시작일시
        breakdownStartDt: '',  // 고장 시작일
        breakdownStartTime: '',  // 고장 시작시간
        breakdownEndDtm: '',  // 고장 종료일시
        breakdownEndDt: '',  // 고장 종료일
        breakdownEndTime: '',  // 고장 종료시간
        breakdownTime: '',  // 고장 지속시간
        breakdownMttf: '',
        remark: '',  // 추가조치사항
        breakRemark: '',  // 추가조치사항
        causeList: [],
        phenomenonList: [],
        actionList: [],
        causes: [],
        phenomenons: [],
        actions: [],
      },
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'PUT',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.disabledObj.disabled
    },
    breakdowned() {
      return this.data.breakdownFlag == 'Y'
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workorder.result.work.breakdown.url;
      this.saveUrl = transactionConfig.wod.workorder.result.breakdown.update.url;
      
      this.getData();
    },
    getData() {
      if (this.param.workResultId && this.param.equipmentCd) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          workResultId: this.param.workResultId,
          equipmentCd: this.param.equipmentCd,
          userId: this.$store.getters.user.userId,
        };
        this.$http.request((_result) => {
          this.$set(this.data, 'workResultBreakdownId', _result.data.workResultBreakdownId)
          this.$set(this.data, 'equipmentCd', this.param.equipmentCd)
          this.$set(this.data, 'workResultId', this.param.workResultId)
          this.$set(this.data, 'breakdownFlag', _result.data.breakdownFlag)
          this.$set(this.data, 'remark', _result.data.remark)
          this.$set(this.data, 'breakRemark', _result.data.breakRemark)
          this.$set(this.data, 'causes', [])
          this.$set(this.data, 'actions', [])
          this.$set(this.data, 'phenomenons', [])

          if (_result.data.causeList.length > 0) {
            this.$_.forEach(_result.data.causeList, item1 => {
              this.data.causes.push(item1.resultTypeCd);
            });
          }
          if (_result.data.actionList.length > 0) {
            this.$_.forEach(_result.data.actionList, item2 => {
              this.data.actions.push(item2.resultTypeCd);
            });
          }
          if (_result.data.causeList.length > 0) {
            this.$_.forEach(_result.data.phenomenonList, item3 => {
              this.data.phenomenons.push(item3.resultTypeCd);
            });
          }

          this.$set(this.attachInfo, 'taskKey', this.data.workResultBreakdownId)
        },);
      }
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.detailUrl + '/prev';
          this.$http.type = 'GET';
          this.$http.param = {
            equipmentCd: this.data.equipmentCd,
            breakdownStartDt: this.data.breakdownStartDtm.split(' ')[0],
            breakdownStartTime: this.data.breakdownStartDtm.split(' ')[1],
          }
          this.$http.request((_result) => {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                this.data.breakdownStartDt = this.data.breakdownStartDtm.split(' ')[0];
                this.data.breakdownStartTime = this.data.breakdownStartDtm.split(' ')[1];
                this.data.breakdownEndDt = this.data.breakdownEndDtm.split(' ')[0];
                this.data.breakdownEndTime = this.data.breakdownEndDtm.split(' ')[1];

                this.data.causeList = [];
                this.data.actionList = [];
                this.data.phenomenonList = [];
                if (this.data.causes.length > 0) {
                  this.$_.forEach(this.data.causes, item1 => {
                    this.data.causeList.push({
                      workResultBreakdownId: this.data.workResultBreakdownId,
                      breakdownResultTypeCd: 'CAUSE',
                      resultTypeCd: item1,
                    });
                  });
                }
                if (this.data.actions.length > 0) {
                  this.$_.forEach(this.data.actions, item2 => {
                    this.data.actionList.push({
                      workResultBreakdownId: this.data.workResultBreakdownId,
                      breakdownResultTypeCd: 'ACTION',
                      resultTypeCd: item2,
                    });
                  });
                }
                if (this.data.phenomenons.length > 0) {
                  this.$_.forEach(this.data.phenomenons, item3 => {
                    this.data.phenomenonList.push({
                      workResultBreakdownId: this.data.workResultBreakdownId,
                      breakdownResultTypeCd: 'PHENOMENON',
                      resultTypeCd: item3,
                    });
                  });
                }
                
                if (_result.data) {
                  let start = this.$comm.moment(_result.data.breakdownEndDtm)
                  let end = this.$comm.moment(this.data.breakdownStartDtm)
                  let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
                  let diffSeconds = parseInt(diffMinutes % 60);
                  this.data.breakdownMttf = parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : '');
                } else {
                  this.data.breakdownMttf = 0;
                }
                console.log('this.data.breakdownMttf',this.data.breakdownMttf)

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          },);
          
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey', this.data.workResultBreakdownId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getData();
    },
    dateChanged() {
      if (this.data.breakdownStartDtm && this.data.breakdownEndDtm) {
        let start = this.$comm.moment(this.data.breakdownStartDtm)
        let end = this.$comm.moment(this.data.breakdownEndDtm)
        let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
        let diffSeconds = parseInt(diffMinutes % 60);
        this.$set(this.data, 'breakdownTime', parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : ''))
      }
    }
  }
};
</script>